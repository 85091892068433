<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <!-- <div class="row">
      <div class="col-md-12">
        <filter-regional class="mr-2" v-model:value="region" v-can:hide.distributor />
        <filter-province
          class="mr-2 mt-2"
          v-model:value="provinsi"
          v-model:region="region"
          v-can:hide.distributor
        />
        <filter-area
          class="mt-2"
          v-model:value="area"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-can:hide.distributor
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-kabupaten-kota
          class="mr-2 mt-2"
          v-model:value="kabupaten"
          v-model:area="area"
          v-can:hide.distributor
        />
        <filter-lt
          class="mr-2 mt-2"
          v-model:value="lt"
          v-model:lt="lt"
          v-model:region="region"
          v-model:area="area"
          v-model:provinsi="provinsi"
          v-model:kabupaten="kabupaten"
        />
        <a-select
          :options="[
            { value: null, label: 'Semua' },
            { value: 2, label: 'Approve ASM' },
            { value: 1, label: 'Approve TSO' },
            { value: 0, label: 'New' },
            { value: 6, label: 'Hapus' },
          ]"
          class="mt-2"
          style="width: 300px;"
          placeholder="Semua Status"
          v-model:value="status"
        ></a-select>
      </div>
    </div> -->
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuate Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end mb-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          @click="modalShowUpload = true"
          class="ml-2 w-30"
          title="Upload data"
          type="primary"
        >
          Upload
          <i class="fe fe-upload ml-2" />
        </a-button>
      </div>
    </div>
    <div class="table-responsive text-nowrap">
      <md-table
        :columns="columns"
        :data-source="data"
        size="small"
        row-key="id"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #file_name="{ text }">
          {{ text.filename }}
        </template>
        <template #upload_by="{ text }">
          {{ text.upload_by }}
        </template>
        <template #upload_at="{ text }">
          {{ text.upload_at }}
        </template>
        <template #count_success="{ text }">
          {{ text.count_success }}
        </template>
        <template #count_failed="{ text }">
          {{ text.count_failed }}
        </template>
        <template #status="{ text }">
          {{ text.progress + ' %' }} <span>{{ text.status_progress }}</span>
          <a-progress :percent="text.progress" :show-info="false" />
        </template>
        <template #action="{record}">
          <a-tooltip
            title="Hapus"
            v-if="
              record.status_progress != 'paused' &&
                record.status_progress != 'progress' &&
                record.status_progress != 'complated'
            "
          >
            <a-button @click="deleteProcess" class="button mx-1 btn-sm" size="small">
              <i class="fe fe-trash" />
            </a-button>
          </a-tooltip>

          <a-tooltip title="Play" v-if="record.status_progress === 'paused'">
            <a-button @click="runProcess" class="button mx-1 btn-sm" size="small">
              <i class="fe fe-play" />
            </a-button>
          </a-tooltip>

          <a-tooltip title="Pause" v-if="record.status_progress === 'progress'">
            <a-button @click="pauseProcess" class="button mx-1 btn-sm" size="small">
              <span class="mr-2">Pause</span>
              <i class="fe fe-pause" />
            </a-button>
          </a-tooltip>

          <a-tooltip
            v-if="record.status_progress === `` && record.progress === 0"
            title="Proses Data"
          >
            <a-button @click="initProcess" class="button mx-1 btn-sm" size="small">
              <i class="fe fe-file-plus" />
            </a-button>
          </a-tooltip>

          <a-tooltip
            v-if="record.status_progress === 'complated' || record.status_progress === 'paused'"
            title="Reverse"
          >
            <a-button @click="reverseProcess" class="button mx-1" size="small">
              <span class="mr-2" v-if="record.status_progress === 'complated'">Reverse</span>
              <i class="fe fe-rotate-ccw" />
            </a-button>
          </a-tooltip>
          <a-tooltip v-if="record.status_progress != ''" title="Detail Data">
            <router-link :to="`/master/master-data-rmx/${record.id}/detail`">
              <a-button class="button mx-1 btn-sm" size="small">
                <i class="fe fe-list" />
              </a-button>
            </router-link>
          </a-tooltip>
        </template>
      </md-table>
    </div>
  </div>

  <a-modal
    v-model:visible="modalShowUpload"
    :title="'Form Upload Data RMX'"
    width="500px"
    @ok="modalShowUpload = false"
  >
    <a-input
      required
      type="file"
      class="w-100 mb-2"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ></a-input>
    <span class="text-primary">Download Template</span>
    <template #footer>
      <a-button key="submit">Batal</a-button>
      <a-button key="submit" type="primary">Upload</a-button>
    </template>
  </a-modal>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs, h } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterLt from '@/components/filter/FilterLt'
// import FilterDistributor from '@/components/filter/FilterDistributor'
// import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import qs from 'qs'
import moment from 'moment'
import { serialize } from 'object-to-formdata'
import useUserInfo from '@/composables/useUserInfo'

const acolumns = [
  {
    title: 'File Name',
    slots: { customRender: 'file_name' },
  },
  {
    title: 'Upload By',
    slots: { customRender: 'upload_by' },
  },
  {
    title: 'Upload At',
    slots: { customRender: 'upload_at' },
  },
  {
    title: 'Count Data Sukses',
    slots: { customRender: 'count_success' },
  },
  {
    title: 'Count Data Gagal',
    slots: { customRender: 'count_failed' },
  },
  {
    title: 'Status',
    slots: { customRender: 'status' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  },
}
export default {
  name: 'MFilter',
  components: {
    // Modall,
    // FilterRegional,
    // FilterProvince,
    // FilterArea,
    // FilterDistributor,
    // FilterLt,
    // FilterWilayahSemua,
    // FilterKabupatenKota,
  },
  setup() {
    const route = useRoute()
    const { isDistributor, vendor_id, regions, areas } = useUserInfo()
    // const modalRef = ref('')

    const modalShowUpload = ref(false)

    const loading = ref(false)

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)

    const region = ref([])
    const provinsi = ref([])
    const kabupaten = ref([])
    const lt = ref([])
    const area = ref([])
    const distributors = ref([])
    const modalRef = ref('')

    const dataRegional = ref([])
    const dataProvince = ref([])
    const dataArea = ref([])
    const status = ref(null)

    const columns = ref([])
    const data = ref([
      {
        filename: 'Raw data RMX V3(2022-03-05)',
        upload_by: 'Fauzan Adhim',
        upload_at: '16 Maret 2023',
        count_success: 1000,
        count_failed: 5,
        progress: 100,
        status_progress: 'complated',
      },
      {
        filename: 'Raw data RMX V2(2021-03-05)',
        upload_by: 'Fauzan Adhim',
        upload_at: '18 Maret 2023',
        count_success: 500,
        count_failed: 40,
        progress: 50,
        status_progress: 'progress',
      },
      {
        filename: 'Raw data RMX V4(2021-03-05)',
        upload_by: 'Fauzan Adhim',
        upload_at: '18 Maret 2021',
        count_success: 500,
        count_failed: 100,
        progress: 75,
        status_progress: 'paused',
      },
      {
        filename: 'Raw data RMX V1(2021-03-05)',
        upload_by: 'Fauzan Adhim',
        upload_at: '18 Maret 2021',
        count_success: 0,
        count_failed: 0,
        progress: 0,
        status_progress: '',
      },
    ])
    const q = ref('')
    const searchInput = ref(null)
    const active = ref(null)

    const isPosting = ref(false)
    const formState = ref({
      status_approval: 0,
      foto_ktp: null,
      status_approval: 0,
      wilayah_id: null,
      type: 1,
      vendors: [],
    })

    const vendors = ref({
      vendor_id: [],
    })

    const errorMessage = ref(null)

    columns.value = acolumns
    if (isDistributor.value) {
      distributors.value.push(vendor_id.value)
      regions.value.forEach(item => {
        region.value.push(item.id)
      })
      areas.value.forEach(item => {
        area.value.push(item.id)
      })
    }

    const startRow = ref(1)
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: region.value,
        area: area.value,
        distributor: distributors.value,
        provinsi: provinsi.value,
        kabupaten: kabupaten.value,
        // produk: products.value,
        // brand: brands.value,
        lt: lt.value,
        status: status.value,
        q: q.value,
        active: active.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/lt', {
          params: _params,
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const { items, _meta } = response.data
          // data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const lihatPengguna = id => {
      apiClient.get(`/api/lt/${id}`).then(response => {
        const data = response.data
        formState.value = data
        modal3Visible.value = true
        formState.value.title_state = 'Lihat Toko'
        formState.value.readOnly = true
        apiClient
          .get(`/api/lt/${id}/codes`)
          .then(({ data }) => {
            formState.value.foto = formState.value.foto_toko
            formState.value.customerCodes = Object.assign([], data.items)
            formState.value.vendorRaw = formState.value.vendors.map(v => ({
              ...v,
              name: `${v.code} - ${v.name}`,
            }))
            formState.value.vendors = formState.value.vendors.map(v => v.id)
          })
          .catch(error => console.error(error))
      })
    }

    const search = () => {
      fetchData()
    }
    const submit = async () => {
      try {
        await modalRef.value.formRef.validate()
      } catch (error) {
        console.log('submit', error)
        return
      }

      if (formState.value.state === 'new') {
        Modal.confirm({
          title: 'Konfirmasi Ajukan Toko',
          content: 'Apakah anda ingin melanjutkan Ajukan Toko ?',

          onOk() {
            isPosting.value = true

            const form_data = serialize({
              ...formState.value,
              vendors: formState.value.vendors.map(id => ({ vendor_id: id })),
            })
            apiClient
              .post('/api/lt', form_data)
              .then(({ data }) => {
                const vendor_id = data.id
                const codes = formState.value.customerCodes
                const promises = []
                codes.forEach(c => {
                  c.vendor_id = vendor_id
                  const post = apiClient.post('/api/lt/' + vendor_id + '/codes', c).then(r => {
                    console.log('codes', r)
                  })
                  promises.push(post)
                })
                Promise.all(promises).catch(() =>
                  message.warning('Gagal simpan kode referensi!, silakan cek data Toko.'),
                )
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil ditambah')
              })
              .catch(({ response: { data, status } }) => {
                if (status === 422) {
                  data[0].message
                } else data.message = 'Gagal menyimpan data! cek data kembali'
                Modal.error({
                  title: 'Gagal Simpan',
                  content: data[0].message,
                  onOk() {
                    console.log('ok')
                  },
                })
              })
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      } else if (formState.value.state === 'edit') {
        Modal.confirm({
          title: 'Konfirmasi Edit Toko',
          content: 'Apakah anda ingin melanjutkan Edit Toko ?',
          onOk() {
            isPosting.value = true

            // const form_data = new FormData()
            // for (var key in formState.value) {
            //   if (key === 'active' || key === 'kelengkapan_dokumen') {
            //     form_data.append(key, formState.value[key] ? 1 : 0)
            //   } else
            //     form_data.append(key, formState.value[key] === null ? '' : formState.value[key])
            // }
            const form_data = serialize({
              ...formState.value,
              vendors: formState.value.vendors.map(id => ({ vendor_id: id })),
            })
            apiClient
              .post('/api/lt/' + formState.value.id + '?_method=PUT', form_data)
              .then(({ data }) => {
                const vendor_id = data.id
                const codes = formState.value.customerCodes
                const promises = []
                codes
                  .filter(c => c.id === null)
                  .forEach(c => {
                    c.vendor_id = vendor_id
                    const post = apiClient.post('/api/lt/' + vendor_id + '/codes', c).then(r => {
                      console.log('edit post codes', r)
                    })
                    promises.push(post)
                  })
                codes
                  .filter(c => c.is_deleted)
                  .forEach(c => {
                    c.vendor_id = vendor_id
                    const _delete = apiClient
                      .delete('/api/lt/' + vendor_id + '/codes/' + c.id)
                      .then(r => {
                        console.log('delete codes', r)
                      })
                    promises.push(_delete)
                  })
                Promise.all(promises).catch(() =>
                  message.warning('Gagal simpan kode referensi!, silakan cek data Toko.'),
                )
                modal3Visible.value = false
                isPosting.value = false
                fetchData()
                message.success('Berhasil disimpan')
              })
              .catch(e => message.error('Gagal mengupdate!'))
              .finally(() => (isPosting.value = false))
          },
          onCancel() {},
        })
      }
    }
    const visible = ref(false)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isDownloading: false,
    })

    provide('formState', formState)

    onMounted(() => {
      const { distributor: disti_id } = route.query
      if (disti_id) {
        if (!distributor.value.includes(Number(disti_id))) distributor.value.push(Number(disti_id))
      }
      fetchData()
    })

    // modal confirm
    const pauseProcess = async () => {
      Modal.confirm({
        title: 'Konfirmasi Jeda Proses Data RMX',
        content: 'Apakah anda sudah yakin melanjutkan proses ini ?',
        okText: 'Lanjutkan',
        cancelText: 'Batal',
        onOk() {},
        onCancel() {},
      })
    }

    const reverseProcess = async () => {
      Modal.confirm({
        title: 'Konfirmasi Revert Data RMX',
        content: 'Apakah anda sudah yakin melanjutkan proses ini ?',
        okText: 'Lanjutkan',
        cancelText: 'Batal',
        onOk() {},
        onCancel() {},
      })
    }

    const runProcess = async () => {
      Modal.confirm({
        title: 'Konfirmasi Proses Data RMX',
        content: 'Apakah anda sudah yakin melanjutkan proses ini ?',
        okText: 'Lanjutkan',
        cancelText: 'Batal',
        onOk() {},
        onCancel() {},
      })
    }

    const initProcess = async () => {
      Modal.confirm({
        title: 'Konfirmasi Proses Data RMX',
        content: 'Apakah anda sudah yakin melanjutkan proses ini ?',
        okText: 'Lanjutkan',
        cancelText: 'Batal',
        onOk() {},
        onCancel() {},
      })
    }

    const deleteProcess = async () => {
      Modal.confirm({
        title: 'Konfirmasi Delete Source Data RMX',
        content: 'Apakah anda sudah yakin melanjutkan proses ini ?',
        okText: 'Lanjutkan',
        cancelText: 'Batal',
        onOk() {},
        onCancel() {},
      })
    }

    return {
      // modal confirm
      pauseProcess,
      reverseProcess,
      runProcess,
      deleteProcess,
      initProcess,
      // modal confirm

      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      rowSelection,
      columns,
      data,
      q,
      searchInput,
      startRow,
      region,
      provinsi,
      kabupaten,
      lt,
      area,
      distributors,
      state,
      ...toRefs(state),
      search,
      modalShowUpload,
      isPosting,
      formState,
      visible,
      lihatPengguna,
      handleTableChange,
      submit,
      dataRegional,
      dataProvince,
      dataArea,
      loading,
      confirm,
      modalRef,
      active,
      errorMessage,
      status,
      vendors,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.ant-table-striped :deep(.table-striped) {
  background-color: #fafafa;
}
</style>
